<template>
    <div class="cs-multi-column-text cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base" :class="rootClasses">
            <div class="container">
                <div class="header-content">
                    <ColorSplit class="cs-title" :data="data.title" />
                    <h3 class="cs-sub-title" v-html="data.subTitle"></h3>
                </div>
                <div class="row justify-content-center">
                    <div
                        :class="`col-12 col-md-6 col-lg-${data.columns}`"
                        v-for="(item, key) in data.textList"
                        :key="key"
                    >
                        <h6 class="cs-col-title">
                            {{ item.title }}
                        </h6>
                        <span class="cs-text" v-html="item.text"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'MulticolumnText',
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
    },
    data() {
        return {}
    },
    computed: {
        ...computed('MulticolumnText'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/main.scss';
.cs-multi-column-text {
    display: flex;
    justify-content: center;

    &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-sub-title{
                color:$sub_title_color;
            }
        *, ::v-deep *{
                color:white;
            }
        }

        &light {
            @include cs-block-light;
            .cs-sub-title{
                color: $sub_title_color_light;
            }
        }

        &dark {
            @include cs-block-dark;

            .cs-sub-title{
                color: $sub_title_color_dark;
            }
        }
    }

    .cs-block-base {
        .header-content {
            z-index: 20;
            position: relative;
            text-align: center;
            margin-bottom: $header_margin !important;
            
            &:last-child {
                margin-bottom: 0 !important;
            }
    
            .cs-title {
                @include title;
                margin-bottom: 20px;
            }
        }           
    }
}
</style>